<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form class="ml-1 mr-1 mt-1">
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Numero: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Numero"
                      rules="required|alpha-num|length:4"
                    >
                      <b-form-input
                        v-model="serieData.numero"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Anexo: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Anexo"
                      rules="required|digits:4"
                    >
                      <b-form-input
                        v-model="serieData.anexo"
                        type="number"
                        @keypress="isNumberInt($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Dirección: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Direccion"
                      rules="required"
                    >
                      <b-form-input
                        v-model="serieData.direccion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row class="text-center">
            <b-col
              md="12"
              class="mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Guardar</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class=""
                @click="cancelar"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <!-- modal -->
</template>

<script>
import store from '@/store/index'
import {
  BRow, BCol, BCard, BForm, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  // eslint-disable-next-line no-unused-vars
  required, alphaNum, length, digits,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      serieData: {
        numero: '',
        anexo: '',
        direccion: '',
      },
    }
  },
  mounted() {
    this.getInfoByID()
  },
  methods: {
    async Guardar() {
      const request = {
        url: `/api/series/${this.$route.params.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.serieData,
      }
      console.log(request.data)
      try {
        const respRoles = await store.dispatch('back/EXECUTE', request)
        if (respRoles.status) {
          this.sendMessage('Serie actualizada satisfactoriamente', 'EditIcon', 'success')
          this.$router.push({ name: 'serie' })
        } else {
          this.sendMessage('Error', 'AlertCircleIcon', 'danger')
          console.log(respRoles)
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    async getInfoByID() {
      const request = {
        url: `/api/series/${this.$route.params.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const respRoles = await store.dispatch('back/EXECUTE', request)
      this.serieData.numero = respRoles.numero
      this.serieData.anexo = respRoles.anexo
      this.serieData.direccion = respRoles.direccion
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.Guardar()
        }
      })
    },
    cancelar() {
      this.$router.push({ name: 'serie' })
    },
    // eslint-disable-next-line consistent-return
    isNumberInt(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    sendMessage(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
</style>
